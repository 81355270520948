
<template>
<div class="q-pa-md">
  <p style="line-height:2.0em;">The following domains are shown as available. The verified column shows whether the domain was TXT verified or listed by a user as available from a partner site.</p></div>

  <div class="indent row justify-start">
      <q-input dense debounce="300" v-model="filter" placeholder="Search" style="min-width: 150px">
          <template v-slot:append>
            <q-icon name="search"></q-icon>
          </template>
        </q-input>
        <!-- <q-checkbox left-label v-model="binonly" label="Show BIN Only" class="indent"></q-checkbox>
        <q-checkbox left-label v-model="minonly" label="Show MIN Only" class="indent"></q-checkbox> -->

  </div>
<!-- 
  <div class="q-pa-md gap">
      <q-badge color="primary">
      Length 
      </q-badge>

      <q-range dense debounce="100"
      class="q-mt-xs indent"
      v-model="firstModel"
      label-always
      :min="1"
      :max="64"
      :inner-min="1"
      :inner-max="64"
      style="max-width: 200px"
    >
      <template v-slot:append>
        <div
          v-for="marker in scope.markerList"
          :key="marker.index"
          :class="[ `text-deep-orange-${2 + Math.ceil(marker.value / 2) }`, marker.classes ]"
          :style="marker.style"
          @click="model = marker.value"
        >{{ marker.value }}</div>
      </template>
    </q-range>
</div> -->

<div class="q-pa-md">
    <q-table
      title="Available"
      :rows="rows"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :visible-columns="visibleColumns"
      :pagination="pagination"
          >

      <template v-slot:top="props">
        <div class="col-2 q-table__title">Available</div>
        
        <q-space />
        <q-select
          v-model="visibleColumns"
          multiple
          borderless
          dense
          options-dense
          :display-value="$q.lang.table.columns"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          style="min-width: 150px"
        />
        <q-btn
          flat round dense
          :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
          @click="props.toggleFullscreen"
          class="q-ml-md"
        />
      </template>
      <template v-slot:body-cell="props">

        <q-td :props="props">
          <span v-if="props.col.name==='verified'" style="text-align:left;">               
            <q-icon v-if="props.value===true" name="check_box" style="color:green"></q-icon>
            <q-icon v-else name="disabled_by_default" style="color:#aa0000;"></q-icon>
          </span>
          <span v-else-if="props.col.name==='dan'" style="text-align:left;">    
            <q-item clickable tag="a" target="_blank" :href="this.$danUrl + props.row.sld + '.' + props.row.tld" class="q-item-limit">   
            <q-icon v-if="props.value===true" name="check_box" style="color:green"></q-icon>
            <q-icon v-else name="disabled_by_default" style="color:#aa0000;"></q-icon>
          </q-item>             
          </span>
          <span v-else-if="props.col.name==='sedo'">            
            <q-item clickable tag="a" target="_blank" :href="this.$sedoUrl + props.row.sld + '.' + props.row.tld" class="q-item-limit">   
            <q-icon v-if="props.value===true" name="check_box" style="color:green"></q-icon>
            <q-icon v-else name="disabled_by_default" style="color:#aa0000;"></q-icon>
          </q-item>  
          </span>
          <span v-else-if="props.col.name==='afternic'">            
            <q-item clickable tag="a" target="_blank" :href="this.$afternicUrl + props.row.sld + '.' + props.row.tld" class="q-item-limit">   
            <q-icon v-if="props.value===true" name="check_box" style="color:green"></q-icon>
            <q-icon v-else name="disabled_by_default" style="color:#aa0000;"></q-icon>
          </q-item>  
          </span>          
          <span v-else-if="props.col.name==='dateAdded'">            
            {{formatDate(props.value)}}
          </span>
          <span v-else-if="props.col.name==='uppercase'">            
            {{props.value.toUpperCase()}}
          </span>
        <span v-else>             
            {{props.value}}</span>
        </q-td>
      </template>
      <template v-slot:top-left="props">
        <TablePagination
          :pagination="props.pagination"
          :filter="table.filter"
          @request="request"
        />
      </template>
    </q-table>
  </div>

</template>

<style>

td .q-item {
  min-height: auto!important;;
}
.indent{
    margin-left:15px!important;
  }

  .gap{
    margin-top:20px!important;
  }

  span{
      font-size: 15px;
      font-weight: 350;
      line-height: 1em;
      color: #2F4F4F;
      word-break: break-all;
  }

  .q-table th {
      font-size: 15px!important;
      font-weight: 550;
      line-height: 2em;
      color: #2F4F4F;
      word-break: break-all;
      text-transform: uppercase;
  }

  .heading-text {
      font-size: 20px!important;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: break-all;
  }

  .q-toggle__label { 
      font-size: 18px!important;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: break-all;
  }

  .venue-image img{
      max-height: 35px!important;
  }

  tr:nth-child(even) {
  background-color: #F0F0F0 !important;
}
</style>

<script>

var pagination = 20;

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Domain Name',
    align: 'left',
    field: row => row.sld + "." + row.tld,
    format: val => `${val}`,
    sortable: true
  },
  { name: 'verified', label: 'Verified', field: 'verified', align: 'left', sortable: true },
  { name: 'tld', label: 'Tld', field: 'tld', align: 'left', sortable: true },
  { name: 'dan', label: 'Dan', field: 'venue1', align: 'left', sortable: true },
  { name: 'sedo', label: 'Sedo', field: 'venue2', align: 'left', sortable: true },
  { name: 'afternic', label: 'Afternic', field: 'venue3', align: 'left', sortable: true },
  { name: 'len', label: 'Length', field: 'len', align: 'left', sortable: true },
  { name: 'dateAdded', label: 'Date Added', field: 'dateAdded', align: 'left', sortable: true },
  {
    name: 'uppercase',
    label: 'Uppercase',
    align: 'left',
    field: row => row.sld + "." + row.tld,
    format: val => `${val}`,
    sortable: true
  }]

  import { defineComponent, onMounted, ref } from "vue";
  import axios from 'axios'
  import dayjs from 'dayjs';

  const filter = ref('')
  
  const binonly = ref(false)
  const minonly = ref(false)

export default {
  methods: {
        formatDate(dateString) {
            const date = dayjs(dateString);
                // Then specify how you want your dates to be formatted
            return date.format('MMM D, YYYY');
        }
    },
setup () {   
    pagination = {
          rowsPerPage: 20
        };

    const rows = ref([]);
    onMounted(async () => {

      const res = await axios.get("https://api.dndatabase.com/domains/randomorder", );
      var resFiltered = res.data.filter(item => item.sld.includes(""));

      rows.value = resFiltered;
    });

      return {
        rows,
        filter,
        pagination,columns,
        defineComponent,
        visibleColumns: ref([ 'sld', 'verified', 'dan', 'sedo', 'afternic', 'len', 'tld']),
        firstModel: {
        min: 1,
        max: 64
      },
      };
  },
data () {       
  onMounted(async () => {

      var resFiltered = this.rows.value;
      if(binonly.value === false)
      {
        resFiltered = resFiltered.filter(item => item.tld.includes("xyz"));
      }
      this.rows.value = resFiltered;
    });
    
    return {
        binonly,
        minonly
      }
  }
}
</script>