import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App);

app.config.globalProperties.$danUrl = "https://dan.com/buy-domain/";
app.config.globalProperties.$sedoUrl = "https://sedo.com/search/details/?domain=";
app.config.globalProperties.$afternicUrl = "https://www.afternic.com/domain/";

app.use(Quasar, {
    
    config: {
      
    }
  }, quasarUserOptions);

createApp(App).use(Quasar, quasarUserOptions).mount('#app')
app.mount('#app');

