<template>
      <suspense>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          DN Database - Open API Experiment (Powered by api.dndatabase.com)
        </q-toolbar-title>

        <div>Version 0.1.2</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-white-2"
    >
      <q-list>
        <q-item-label header>Menu</q-item-label>
        <q-item clickable tag="a" href="/">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Available</q-item-label>
            <q-item-label class="text-caption" caption>Domains</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="https://api.dndatabase.com">
          <q-item-section avatar>
            <q-icon name="code" />
          </q-item-section>
          <q-item-section>
            <q-item-label>API</q-item-label>
            <q-item-label class="text-caption" caption>Guide</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="https://api.dndatabase.com/images/txt.png">
          <q-item-section avatar>
            <q-icon name="help" />
          </q-item-section>
          <q-item-section>
            <q-item-label>TXT Builder</q-item-label>
            <q-item-label class="text-caption" caption>Coming Soon but click to see a raw example</q-item-label>
          </q-item-section>
        </q-item>
        <br/>
        <br/>
        <q-item class="text-center">
          <q-item-section>
            <q-item-label>About Us</q-item-label>
            <q-item-label class="text-caption" caption>
                           
              <p>
                Welcome to DN Database. Using the API from api.dndatabase.com, available to all, you can list a name you have for sale and the venues they are listed at. Anyone can pull this data.

              Domains are verified frequently by TXT verification to ensure data is current. If the TXT record is missing or invalid when checked the domain is removed.
              <br/>
              If the TXT record appears again within 24 hours the domain will be available in the list again. You also control all the information displayed in the same TXT record.
              <br/>
              The API is open and this lightweight site just consumes the API as anyone can.
              <br/>
              Lastly, we are #BuildingInPublic so any feedback on this API is always welcome as we add more venues and capabilities to the API.
            
          </p>

            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="text-center">
          <q-item-section>
            <q-item-label>Coming Soon</q-item-label>
            <q-item-label caption>                           
              <p>
                Search
                <br/>
                TXT Builder
                <br/>
                More Venues Supported
              <br/>
              </p>

            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

    </q-drawer>

    <q-page-container>

      <DomainList />

    </q-page-container>
  </q-layout>
</suspense>
</template>

<style>
  .q-list{
      font-size: 15px;
      font-weight: 850;
      line-height: 2em;
      color: #2F4F4F;
      word-break: break-all;
  }

  .text-caption {
    font-size: 15px!important;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: normal;
  }

  .text-caption p{
    font-size: 15px;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: normal;
  }

</style>

<script>
import { ref } from 'vue'
import DomainList from './components/DomainList.vue'

export default {
  name: 'LayoutDefault',

  components: {
    DomainList
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
